export const may = [
  {
    day: 0,
    sehri: [0, 0, "AM"],
    fajar: [0, 0, "AM"],
    sunrise: [0, 0, "AM"],
    dhuhur: [0, 0, "PM"],
    asr: [0, 0, "PM"],
    magrib: [0, 0, "PM"],
    isha: [0, 0, "PM"],
  },
  {
    day: 1,
    sehri: [3, 57, "AM"],
    fajar: [4, 7, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 31, "PM"],
    magrib: [6, 31, "PM"],
    isha: [7, 50, "PM"],
  },
  {
    day: 2,
    sehri: [3, 57, "AM"],
    fajar: [4, 7, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 31, "PM"],
    magrib: [6, 31, "PM"],
    isha: [7, 50, "PM"],
  },
  {
    day: 3,
    sehri: [3, 57, "AM"],
    fajar: [4, 7, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 31, "PM"],
    magrib: [6, 31, "PM"],
    isha: [7, 50, "PM"],
  },
  {
    day: 4,
    sehri: [3, 57, "AM"],
    fajar: [4, 7, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 31, "PM"],
    magrib: [6, 31, "PM"],
    isha: [7, 50, "PM"],
  },
  {
    day: 5,
    sehri: [3, 52, "AM"],
    fajar: [4, 2, "AM"],
    sunrise: [5, 21, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 31, "PM"],
    magrib: [6, 33, "PM"],
    isha: [7, 53, "PM"],
  },
  {
    day: 6,
    sehri: [3, 52, "AM"],
    fajar: [4, 2, "AM"],
    sunrise: [5, 21, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 31, "PM"],
    magrib: [6, 33, "PM"],
    isha: [7, 53, "PM"],
  },
  {
    day: 7,
    sehri: [3, 52, "AM"],
    fajar: [4, 2, "AM"],
    sunrise: [5, 21, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 31, "PM"],
    magrib: [6, 33, "PM"],
    isha: [7, 53, "PM"],
  },
  {
    day: 8,
    sehri: [3, 52, "AM"],
    fajar: [4, 2, "AM"],
    sunrise: [5, 21, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 31, "PM"],
    magrib: [6, 33, "PM"],
    isha: [7, 53, "PM"],
  },
  {
    day: 9,
    sehri: [3, 52, "AM"],
    fajar: [4, 2, "AM"],
    sunrise: [5, 21, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 31, "PM"],
    magrib: [6, 33, "PM"],
    isha: [7, 53, "PM"],
  },
  {
    day: 10,
    sehri: [3, 48, "AM"],
    fajar: [3, 58, "AM"],
    sunrise: [5, 18, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 32, "PM"],
    magrib: [6, 35, "PM"],
    isha: [7, 57, "PM"],
  },
  {
    day: 11,
    sehri: [3, 48, "AM"],
    fajar: [3, 58, "AM"],
    sunrise: [5, 18, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 32, "PM"],
    magrib: [6, 35, "PM"],
    isha: [7, 57, "PM"],
  },
  {
    day: 12,
    sehri: [3, 48, "AM"],
    fajar: [3, 58, "AM"],
    sunrise: [5, 18, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 32, "PM"],
    magrib: [6, 35, "PM"],
    isha: [7, 57, "PM"],
  },
  {
    day: 13,
    sehri: [3, 48, "AM"],
    fajar: [3, 58, "AM"],
    sunrise: [5, 18, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 32, "PM"],
    magrib: [6, 35, "PM"],
    isha: [7, 57, "PM"],
  },
  {
    day: 14,
    sehri: [3, 48, "AM"],
    fajar: [3, 58, "AM"],
    sunrise: [5, 18, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 32, "PM"],
    magrib: [6, 35, "PM"],
    isha: [7, 57, "PM"],
  },
  {
    day: 15,
    sehri: [3, 45, "AM"],
    fajar: [3, 55, "AM"],
    sunrise: [5, 16, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 32, "PM"],
    magrib: [6, 37, "PM"],
    isha: [8, 0, "PM"],
  },
  {
    day: 16,
    sehri: [3, 45, "AM"],
    fajar: [3, 55, "AM"],
    sunrise: [5, 16, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 32, "PM"],
    magrib: [6, 37, "PM"],
    isha: [8, 0, "PM"],
  },
  {
    day: 17,
    sehri: [3, 45, "AM"],
    fajar: [3, 55, "AM"],
    sunrise: [5, 16, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 32, "PM"],
    magrib: [6, 37, "PM"],
    isha: [8, 0, "PM"],
  },
  {
    day: 18,
    sehri: [3, 45, "AM"],
    fajar: [3, 55, "AM"],
    sunrise: [5, 16, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 32, "PM"],
    magrib: [6, 37, "PM"],
    isha: [8, 0, "PM"],
  },
  {
    day: 19,
    sehri: [3, 45, "AM"],
    fajar: [3, 55, "AM"],
    sunrise: [5, 16, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 32, "PM"],
    magrib: [6, 37, "PM"],
    isha: [8, 0, "PM"],
  },
  {
    day: 20,
    sehri: [3, 42, "AM"],
    fajar: [3, 52, "AM"],
    sunrise: [5, 13, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 33, "PM"],
    magrib: [6, 40, "PM"],
    isha: [8, 3, "PM"],
  },
  {
    day: 21,
    sehri: [3, 42, "AM"],
    fajar: [3, 52, "AM"],
    sunrise: [5, 13, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 33, "PM"],
    magrib: [6, 40, "PM"],
    isha: [8, 3, "PM"],
  },
  {
    day: 22,
    sehri: [3, 42, "AM"],
    fajar: [3, 52, "AM"],
    sunrise: [5, 13, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 33, "PM"],
    magrib: [6, 40, "PM"],
    isha: [8, 3, "PM"],
  },
  {
    day: 23,
    sehri: [3, 42, "AM"],
    fajar: [3, 52, "AM"],
    sunrise: [5, 13, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 33, "PM"],
    magrib: [6, 40, "PM"],
    isha: [8, 3, "PM"],
  },
  {
    day: 24,
    sehri: [3, 42, "AM"],
    fajar: [3, 52, "AM"],
    sunrise: [5, 13, "AM"],
    dhuhur: [11, 58, "AM"],
    asr: [4, 33, "PM"],
    magrib: [6, 40, "PM"],
    isha: [8, 3, "PM"],
  },
  {
    day: 25,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 34, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 6, "PM"],
  },
  {
    day: 26,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 34, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 6, "PM"],
  },
  {
    day: 27,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 34, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 6, "PM"],
  },
  {
    day: 28,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 34, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 6, "PM"],
  },
  {
    day: 29,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 34, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 6, "PM"],
  },
  {
    day: 30,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 34, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 6, "PM"],
  },
  {
    day: 31,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 34, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 6, "PM"],
  },
];
