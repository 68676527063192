export const april = [
  {
    sehri: [0, 0, "AM"],
    fajar: [0, 0, "AM"],
    sunrise: [0, 0, "AM"],
    dhuhur: [0, 0, "PM"],
    asr: [0, 0, "PM"],
    magrib: [0, 0, "PM"],
    isha: [0, 0, "PM"],
  },
  {
    sehri: [4, 27, "AM"],
    fajar: [4, 33, "AM"],
    sunrise: [5, 50, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 18, "PM"],
    isha: [7, 33, "PM"],
  },
  {
    sehri: [4, 27, "AM"],
    fajar: [4, 33, "AM"],
    sunrise: [5, 50, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 19, "PM"],
    isha: [7, 33, "PM"],
  },
  {
    sehri: [4, 27, "AM"],
    fajar: [4, 33, "AM"],
    sunrise: [5, 50, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 19, "PM"],
    isha: [7, 33, "PM"],
  },
  {
    sehri: [4, 26, "AM"],
    fajar: [4, 32, "AM"],
    sunrise: [5, 50, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 19, "PM"],
    isha: [7, 33, "PM"],
  },
  {
    sehri: [4, 24, "AM"],
    fajar: [4, 30, "AM"],
    sunrise: [5, 46, "AM"],
    dhuhur: [12, 5, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 20, "PM"],
    isha: [7, 35, "PM"],
  },
  {
    sehri: [4, 24, "AM"],
    fajar: [4, 30, "AM"],
    sunrise: [5, 32, "AM"],
    dhuhur: [12, 5, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 20, "PM"],
    isha: [7, 35, "PM"],
  },
  {
    sehri: [4, 23, "AM"],
    fajar: [4, 29, "AM"],
    sunrise: [5, 32, "AM"],
    dhuhur: [12, 5, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 21, "PM"],
    isha: [7, 35, "PM"],
  },
  {
    sehri: [4, 22, "AM"],
    fajar: [4, 28, "AM"],
    sunrise: [5, 32, "AM"],
    dhuhur: [12, 5, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 22, "PM"],
    isha: [7, 35, "PM"],
  },
  {
    sehri: [4, 21, "AM"],
    fajar: [4, 27, "AM"],
    sunrise: [5, 32, "AM"],
    dhuhur: [12, 5, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 21, "PM"],
    isha: [7, 35, "PM"],
  },
  {
    sehri: [4, 20, "AM"],
    fajar: [4, 26, "AM"],
    sunrise: [5, 41, "AM"],
    dhuhur: [12, 3, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 22, "PM"],
    isha: [7, 37, "PM"],
  },
  {
    sehri: [4, 19, "AM"],
    fajar: [4, 25, "AM"],
    sunrise: [5, 41, "AM"],
    dhuhur: [12, 3, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 22, "PM"],
    isha: [7, 37, "PM"],
  },
  {
    sehri: [4, 18, "AM"],
    fajar: [4, 25, "AM"],
    sunrise: [5, 41, "AM"],
    dhuhur: [12, 3, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 23, "PM"],
    isha: [7, 37, "PM"],
  },
  {
    sehri: [4, 17, "AM"],
    fajar: [4, 23, "AM"],
    sunrise: [5, 41, "AM"],
    dhuhur: [12, 3, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 23, "PM"],
    isha: [7, 37, "PM"],
  },
  {
    sehri: [4, 15, "AM"],
    fajar: [4, 21, "AM"],
    sunrise: [5, 41, "AM"],
    dhuhur: [12, 3, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 23, "PM"],
    isha: [7, 37, "PM"],
  },
  {
    sehri: [4, 14, "AM"],
    fajar: [4, 20, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 24, "PM"],
    isha: [7, 40, "PM"],
  },
  {
    sehri: [4, 13, "AM"],
    fajar: [4, 19, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 24, "PM"],
    isha: [7, 40, "PM"],
  },
  {
    sehri: [4, 12, "AM"],
    fajar: [4, 18, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 24, "PM"],
    isha: [7, 40, "PM"],
  },
  {
    sehri: [4, 11, "AM"],
    fajar: [4, 17, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 25, "PM"],
    isha: [7, 40, "PM"],
  },
  {
    sehri: [4, 10, "AM"],
    fajar: [4, 16, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 25, "PM"],
    isha: [7, 40, "PM"],
  },
  {
    sehri: [4, 9, "AM"],
    fajar: [4, 15, "AM"],
    sunrise: [5, 33, "AM"],
    dhuhur: [12, 1, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 26, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    sehri: [4, 8, "AM"],
    fajar: [4, 14, "AM"],
    sunrise: [5, 33, "AM"],
    dhuhur: [12, 1, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 26, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    sehri: [4, 7, "AM"],
    fajar: [4, 13, "AM"],
    sunrise: [5, 33, "AM"],
    dhuhur: [12, 1, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 27, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    sehri: [4, 6, "AM"],
    fajar: [4, 12, "AM"],
    sunrise: [5, 33, "AM"],
    dhuhur: [12, 1, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 27, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    sehri: [4, 5, "AM"],
    fajar: [4, 11, "AM"],
    sunrise: [5, 33, "AM"],
    dhuhur: [12, 1, "PM"],
    asr: [4, 30, "PM"],
    magrib: [6, 28, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    sehri: [4, 5, "AM"],
    fajar: [4, 11, "AM"],
    sunrise: [5, 28, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 31, "PM"],
    magrib: [6, 28, "PM"],
    isha: [7, 46, "PM"],
  },
  {
    sehri: [4, 4, "AM"],
    fajar: [4, 10, "AM"],
    sunrise: [5, 28, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 31, "PM"],
    magrib: [6, 29, "PM"],
    isha: [7, 46, "PM"],
  },
  {
    sehri: [4, 3, "AM"],
    fajar: [4, 9, "AM"],
    sunrise: [5, 28, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 31, "PM"],
    magrib: [6, 29, "PM"],
    isha: [7, 46, "PM"],
  },
  {
    sehri: [4, 2, "AM"],
    fajar: [4, 8, "AM"],
    sunrise: [5, 28, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 31, "PM"],
    magrib: [6, 29, "PM"],
    isha: [7, 46, "PM"],
  },
  {
    sehri: [4, 1, "AM"],
    fajar: [4, 7, "AM"],
    sunrise: [5, 28, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 31, "PM"],
    magrib: [6, 30, "PM"],
    isha: [7, 46, "PM"],
  },
  {
    sehri: [4, 0, "AM"],
    fajar: [4, 6, "AM"],
    sunrise: [5, 28, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 31, "PM"],
    magrib: [6, 30, "PM"],
    isha: [7, 46, "PM"],
  },
];
