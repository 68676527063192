export const november = [
  {
    sehri: [0, 0, "AM"],
    fajar: [0, 0, "AM"],
    sunrise: [0, 0, "AM"],
    dhuhur: [0, 0, "PM"],
    asr: [0, 0, "PM"],
    magrib: [0, 0, "PM"],
    isha: [0, 0, "PM"],
  },
  {
    sehri: [4, 41, "AM"],
    fajar: [4, 51, "AM"],
    sunrise: [6, 4, "AM"],
    dhuhur: [11, 45, "AM"],
    asr: [3, 43, "PM"],
    magrib: [5, 23, "PM"],
    isha: [6, 38, "PM"],
  },
  {
    sehri: [4, 41, "AM"],
    fajar: [4, 51, "AM"],
    sunrise: [6, 4, "AM"],
    dhuhur: [11, 45, "AM"],
    asr: [3, 43, "PM"],
    magrib: [5, 23, "PM"],
    isha: [6, 38, "PM"],
  },
  {
    sehri: [4, 41, "AM"],
    fajar: [4, 51, "AM"],
    sunrise: [6, 4, "AM"],
    dhuhur: [11, 45, "AM"],
    asr: [3, 43, "PM"],
    magrib: [5, 23, "PM"],
    isha: [6, 38, "PM"],
  },
  {
    sehri: [4, 41, "AM"],
    fajar: [4, 51, "AM"],
    sunrise: [6, 4, "AM"],
    dhuhur: [11, 45, "AM"],
    asr: [3, 43, "PM"],
    magrib: [5, 23, "PM"],
    isha: [6, 38, "PM"],
  },
  {
    sehri: [4, 43, "AM"],
    fajar: [4, 53, "AM"],
    sunrise: [6, 6, "AM"],
    dhuhur: [11, 45, "AM"],
    asr: [3, 41, "PM"],
    magrib: [5, 21, "PM"],
    isha: [6, 36, "PM"],
  },
  {
    sehri: [4, 43, "AM"],
    fajar: [4, 53, "AM"],
    sunrise: [6, 6, "AM"],
    dhuhur: [11, 45, "AM"],
    asr: [3, 41, "PM"],
    magrib: [5, 21, "PM"],
    isha: [6, 36, "PM"],
  },
  {
    sehri: [4, 43, "AM"],
    fajar: [4, 53, "AM"],
    sunrise: [6, 6, "AM"],
    dhuhur: [11, 45, "AM"],
    asr: [3, 41, "PM"],
    magrib: [5, 21, "PM"],
    isha: [6, 36, "PM"],
  },
  {
    sehri: [4, 43, "AM"],
    fajar: [4, 53, "AM"],
    sunrise: [6, 6, "AM"],
    dhuhur: [11, 45, "AM"],
    asr: [3, 41, "PM"],
    magrib: [5, 21, "PM"],
    isha: [6, 36, "PM"],
  },
  {
    sehri: [4, 43, "AM"],
    fajar: [4, 53, "AM"],
    sunrise: [6, 6, "AM"],
    dhuhur: [11, 45, "AM"],
    asr: [3, 41, "PM"],
    magrib: [5, 21, "PM"],
    isha: [6, 36, "PM"],
  },
  {
    sehri: [4, 46, "AM"],
    fajar: [4, 56, "AM"],
    sunrise: [6, 10, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 39, "PM"],
    magrib: [5, 18, "PM"],
    isha: [6, 34, "PM"],
  },
  {
    sehri: [4, 46, "AM"],
    fajar: [4, 56, "AM"],
    sunrise: [6, 10, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 39, "PM"],
    magrib: [5, 18, "PM"],
    isha: [6, 34, "PM"],
  },
  {
    sehri: [4, 46, "AM"],
    fajar: [4, 56, "AM"],
    sunrise: [6, 10, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 39, "PM"],
    magrib: [5, 18, "PM"],
    isha: [6, 34, "PM"],
  },
  {
    sehri: [4, 46, "AM"],
    fajar: [4, 56, "AM"],
    sunrise: [6, 10, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 39, "PM"],
    magrib: [5, 18, "PM"],
    isha: [6, 34, "PM"],
  },
  {
    sehri: [4, 46, "AM"],
    fajar: [4, 56, "AM"],
    sunrise: [6, 10, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 39, "PM"],
    magrib: [5, 18, "PM"],
    isha: [6, 34, "PM"],
  },
  {
    sehri: [4, 49, "AM"],
    fajar: [4, 59, "AM"],
    sunrise: [6, 13, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 37, "PM"],
    magrib: [5, 16, "PM"],
    isha: [6, 32, "PM"],
  },
  {
    sehri: [4, 49, "AM"],
    fajar: [4, 59, "AM"],
    sunrise: [6, 13, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 37, "PM"],
    magrib: [5, 16, "PM"],
    isha: [6, 32, "PM"],
  },
  {
    sehri: [4, 49, "AM"],
    fajar: [4, 59, "AM"],
    sunrise: [6, 13, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 37, "PM"],
    magrib: [5, 16, "PM"],
    isha: [6, 32, "PM"],
  },
  {
    sehri: [4, 49, "AM"],
    fajar: [4, 59, "AM"],
    sunrise: [6, 13, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 37, "PM"],
    magrib: [5, 16, "PM"],
    isha: [6, 32, "PM"],
  },
  {
    sehri: [4, 49, "AM"],
    fajar: [4, 59, "AM"],
    sunrise: [6, 13, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 37, "PM"],
    magrib: [5, 16, "PM"],
    isha: [6, 32, "PM"],
  },
  {
    sehri: [4, 52, "AM"],
    fajar: [5, 2, "AM"],
    sunrise: [6, 16, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 36, "PM"],
    magrib: [5, 15, "PM"],
    isha: [6, 31, "PM"],
  },
  {
    sehri: [4, 52, "AM"],
    fajar: [5, 2, "AM"],
    sunrise: [6, 16, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 36, "PM"],
    magrib: [5, 15, "PM"],
    isha: [6, 31, "PM"],
  },
  {
    sehri: [4, 52, "AM"],
    fajar: [5, 2, "AM"],
    sunrise: [6, 16, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 36, "PM"],
    magrib: [5, 15, "PM"],
    isha: [6, 31, "PM"],
  },
  {
    sehri: [4, 52, "AM"],
    fajar: [5, 2, "AM"],
    sunrise: [6, 16, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 36, "PM"],
    magrib: [5, 15, "PM"],
    isha: [6, 31, "PM"],
  },
  {
    sehri: [4, 52, "AM"],
    fajar: [5, 2, "AM"],
    sunrise: [6, 16, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 36, "PM"],
    magrib: [5, 15, "PM"],
    isha: [6, 31, "PM"],
  },
  {
    sehri: [4, 55, "AM"],
    fajar: [5, 5, "AM"],
    sunrise: [6, 20, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 35, "PM"],
    magrib: [5, 14, "PM"],
    isha: [6, 31, "PM"],
  },
  {
    sehri: [4, 55, "AM"],
    fajar: [5, 5, "AM"],
    sunrise: [6, 20, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 35, "PM"],
    magrib: [5, 14, "PM"],
    isha: [6, 31, "PM"],
  },
  {
    sehri: [4, 55, "AM"],
    fajar: [5, 5, "AM"],
    sunrise: [6, 20, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 35, "PM"],
    magrib: [5, 14, "PM"],
    isha: [6, 31, "PM"],
  },
  {
    sehri: [4, 55, "AM"],
    fajar: [5, 5, "AM"],
    sunrise: [6, 20, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 35, "PM"],
    magrib: [5, 14, "PM"],
    isha: [6, 31, "PM"],
  },
  {
    sehri: [4, 55, "AM"],
    fajar: [5, 5, "AM"],
    sunrise: [6, 20, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 35, "PM"],
    magrib: [5, 14, "PM"],
    isha: [6, 31, "PM"],
  },
  {
    sehri: [4, 55, "AM"],
    fajar: [5, 5, "AM"],
    sunrise: [6, 20, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 35, "PM"],
    magrib: [5, 14, "PM"],
    isha: [6, 31, "PM"],
  },
];
