export const july = [
  {
    day: 0,
    sehri: [0, 0, "AM"],
    fajar: [0, 0, "AM"],
    sunrise: [0, 0, "AM"],
    dhuhur: [0, 0, "PM"],
    asr: [0, 0, "PM"],
    magrib: [0, 0, "PM"],
    isha: [0, 0, "PM"],
  },
  {
    day: 1,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 14, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 54, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 2,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 14, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 54, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 3,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 14, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 54, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 4,
    sehri: [3, 40, "AM"],
    fajar: [3, 50, "AM"],
    sunrise: [5, 14, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 54, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 5,
    sehri: [3, 42, "AM"],
    fajar: [3, 52, "AM"],
    sunrise: [5, 15, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 54, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 6,
    sehri: [3, 42, "AM"],
    fajar: [3, 52, "AM"],
    sunrise: [5, 15, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 54, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 7,
    sehri: [3, 42, "AM"],
    fajar: [3, 52, "AM"],
    sunrise: [5, 15, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 54, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 8,
    sehri: [3, 42, "AM"],
    fajar: [3, 52, "AM"],
    sunrise: [5, 15, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 54, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 9,
    sehri: [3, 42, "AM"],
    fajar: [3, 52, "AM"],
    sunrise: [5, 15, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 54, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 10,
    sehri: [3, 44, "AM"],
    fajar: [3, 54, "AM"],
    sunrise: [5, 18, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 18, "PM"],
  },
  {
    day: 11,
    sehri: [3, 44, "AM"],
    fajar: [3, 54, "AM"],
    sunrise: [5, 18, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 18, "PM"],
  },
  {
    day: 12,
    sehri: [3, 44, "AM"],
    fajar: [3, 54, "AM"],
    sunrise: [5, 18, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 18, "PM"],
  },
  {
    day: 13,
    sehri: [3, 44, "AM"],
    fajar: [3, 54, "AM"],
    sunrise: [5, 18, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 18, "PM"],
  },
  {
    day: 14,
    sehri: [3, 44, "AM"],
    fajar: [3, 54, "AM"],
    sunrise: [5, 18, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 18, "PM"],
  },
  {
    day: 15,
    sehri: [3, 47, "AM"],
    fajar: [3, 57, "AM"],
    sunrise: [5, 19, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 17, "PM"],
  },
  {
    day: 16,
    sehri: [3, 47, "AM"],
    fajar: [3, 57, "AM"],
    sunrise: [5, 19, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 17, "PM"],
  },
  {
    day: 17,
    sehri: [3, 47, "AM"],
    fajar: [3, 57, "AM"],
    sunrise: [5, 19, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 17, "PM"],
  },
  {
    day: 18,
    sehri: [3, 47, "AM"],
    fajar: [3, 57, "AM"],
    sunrise: [5, 19, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 17, "PM"],
  },
  {
    day: 19,
    sehri: [3, 47, "AM"],
    fajar: [3, 57, "AM"],
    sunrise: [5, 19, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 17, "PM"],
  },
  {
    day: 20,
    sehri: [3, 50, "AM"],
    fajar: [4, 0, "AM"],
    sunrise: [5, 22, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 40, "PM"],
    isha: [8, 14, "PM"],
  },
  {
    day: 21,
    sehri: [3, 50, "AM"],
    fajar: [4, 0, "AM"],
    sunrise: [5, 22, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 40, "PM"],
    isha: [8, 14, "PM"],
  },
  {
    day: 22,
    sehri: [3, 50, "AM"],
    fajar: [4, 0, "AM"],
    sunrise: [5, 22, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 40, "PM"],
    isha: [8, 14, "PM"],
  },
  {
    day: 23,
    sehri: [3, 50, "AM"],
    fajar: [4, 0, "AM"],
    sunrise: [5, 22, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 40, "PM"],
    isha: [8, 14, "PM"],
  },
  {
    day: 24,
    sehri: [3, 50, "AM"],
    fajar: [4, 0, "AM"],
    sunrise: [5, 22, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 40, "PM"],
    isha: [8, 14, "PM"],
  },
  {
    day: 25,
    sehri: [3, 54, "AM"],
    fajar: [4, 4, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 11, "PM"],
  },
  {
    day: 26,
    sehri: [3, 54, "AM"],
    fajar: [4, 4, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 11, "PM"],
  },
  {
    day: 27,
    sehri: [3, 54, "AM"],
    fajar: [4, 4, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 11, "PM"],
  },
  {
    day: 28,
    sehri: [3, 54, "AM"],
    fajar: [4, 4, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 11, "PM"],
  },
  {
    day: 29,
    sehri: [3, 54, "AM"],
    fajar: [4, 4, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 11, "PM"],
  },
  {
    day: 30,
    sehri: [3, 54, "AM"],
    fajar: [4, 4, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 11, "PM"],
  },
  {
    day: 31,
    sehri: [3, 54, "AM"],
    fajar: [4, 4, "AM"],
    sunrise: [5, 24, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 43, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 11, "PM"],
  },
];
