export const february = [
  {
    day: 0,
    sehri: [0, 0, "AM"],
    fajar: [0, 0, "AM"],
    sunrise: [0, 0, "AM"],
    dhuhur: [0, 0, "PM"],
    asr: [0, 0, "PM"],
    magrib: [0, 0, "PM"],
    isha: [0, 0, "PM"],
  },
  {
    day: 1,
    sehri: [5, 16, "AM"],
    fajar: [5, 26, "AM"],
    sunrise: [6, 39, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 8, "PM"],
    magrib: [5, 48, "PM"],
    isha: [7, 4, "PM"],
  },
  {
    day: 2,
    sehri: [5, 16, "AM"],
    fajar: [5, 26, "AM"],
    sunrise: [6, 39, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 8, "PM"],
    magrib: [5, 48, "PM"],
    isha: [7, 4, "PM"],
  },
  {
    day: 3,
    sehri: [5, 16, "AM"],
    fajar: [5, 26, "AM"],
    sunrise: [6, 39, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 8, "PM"],
    magrib: [5, 48, "PM"],
    isha: [7, 4, "PM"],
  },
  {
    day: 4,
    sehri: [5, 16, "AM"],
    fajar: [5, 26, "AM"],
    sunrise: [6, 39, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 8, "PM"],
    magrib: [5, 48, "PM"],
    isha: [7, 4, "PM"],
  },
  {
    day: 5,
    sehri: [5, 16, "AM"],
    fajar: [5, 24, "AM"],
    sunrise: [6, 37, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 11, "PM"],
    magrib: [5, 51, "PM"],
    isha: [7, 6, "PM"],
  },
  {
    day: 6,
    sehri: [5, 14, "AM"],
    fajar: [5, 24, "AM"],
    sunrise: [6, 37, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 11, "PM"],
    magrib: [5, 51, "PM"],
    isha: [7, 6, "PM"],
  },
  {
    day: 7,
    sehri: [5, 14, "AM"],
    fajar: [5, 24, "AM"],
    sunrise: [6, 37, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 11, "PM"],
    magrib: [5, 51, "PM"],
    isha: [7, 6, "PM"],
  },
  {
    day: 8,
    sehri: [5, 14, "AM"],
    fajar: [5, 24, "AM"],
    sunrise: [6, 37, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 11, "PM"],
    magrib: [5, 51, "PM"],
    isha: [7, 6, "PM"],
  },
  {
    day: 9,
    sehri: [5, 14, "AM"],
    fajar: [5, 24, "AM"],
    sunrise: [6, 42, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 11, "PM"],
    magrib: [5, 51, "PM"],
    isha: [7, 6, "PM"],
  },
  {
    day: 10,
    sehri: [5, 12, "AM"],
    fajar: [5, 22, "AM"],
    sunrise: [6, 34, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 14, "PM"],
    magrib: [5, 54, "PM"],
    isha: [7, 9, "PM"],
  },
  {
    day: 11,
    sehri: [5, 12, "AM"],
    fajar: [5, 22, "AM"],
    sunrise: [6, 34, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 14, "PM"],
    magrib: [5, 54, "PM"],
    isha: [7, 9, "PM"],
  },
  {
    day: 12,
    sehri: [5, 12, "AM"],
    fajar: [5, 22, "AM"],
    sunrise: [6, 34, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 14, "PM"],
    magrib: [5, 54, "PM"],
    isha: [7, 9, "PM"],
  },
  {
    day: 13,
    sehri: [5, 12, "AM"],
    fajar: [5, 22, "AM"],
    sunrise: [6, 34, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 14, "PM"],
    magrib: [5, 54, "PM"],
    isha: [7, 9, "PM"],
  },
  {
    day: 14,
    sehri: [5, 12, "AM"],
    fajar: [5, 22, "AM"],
    sunrise: [6, 34, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 14, "PM"],
    magrib: [5, 54, "PM"],
    isha: [7, 9, "PM"],
  },
  {
    day: 15,
    sehri: [5, 9, "AM"],
    fajar: [5, 19, "AM"],
    sunrise: [6, 31, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 16, "PM"],
    magrib: [5, 57, "PM"],
    isha: [7, 11, "PM"],
  },
  {
    day: 16,
    sehri: [5, 9, "AM"],
    fajar: [5, 19, "AM"],
    sunrise: [6, 31, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 16, "PM"],
    magrib: [5, 57, "PM"],
    isha: [7, 11, "PM"],
  },
  {
    day: 17,
    sehri: [5, 9, "AM"],
    fajar: [5, 19, "AM"],
    sunrise: [6, 31, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 16, "PM"],
    magrib: [5, 57, "PM"],
    isha: [7, 11, "PM"],
  },
  {
    day: 18,
    sehri: [5, 9, "AM"],
    fajar: [5, 19, "AM"],
    sunrise: [6, 31, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 16, "PM"],
    magrib: [5, 57, "PM"],
    isha: [7, 11, "PM"],
  },
  {
    day: 19,
    sehri: [5, 9, "AM"],
    fajar: [5, 19, "AM"],
    sunrise: [6, 31, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 16, "PM"],
    magrib: [5, 57, "PM"],
    isha: [7, 11, "PM"],
  },
  {
    day: 20,
    sehri: [5, 6, "AM"],
    fajar: [5, 26, "AM"],
    sunrise: [6, 28, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 19, "PM"],
    magrib: [6, 0, "PM"],
    isha: [7, 14, "PM"],
  },
  {
    day: 21,
    sehri: [5, 6, "AM"],
    fajar: [5, 26, "AM"],
    sunrise: [6, 28, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 19, "PM"],
    magrib: [6, 0, "PM"],
    isha: [7, 14, "PM"],
  },
  {
    day: 22,
    sehri: [5, 6, "AM"],
    fajar: [5, 26, "AM"],
    sunrise: [6, 28, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 19, "PM"],
    magrib: [6, 0, "PM"],
    isha: [7, 14, "PM"],
  },
  {
    day: 23,
    sehri: [5, 6, "AM"],
    fajar: [5, 26, "AM"],
    sunrise: [6, 28, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 19, "PM"],
    magrib: [6, 0, "PM"],
    isha: [7, 14, "PM"],
  },
  {
    day: 24,
    sehri: [5, 6, "AM"],
    fajar: [5, 26, "AM"],
    sunrise: [6, 28, "AM"],
    dhuhur: [12, 16, "PM"],
    asr: [4, 19, "PM"],
    magrib: [6, 0, "PM"],
    isha: [7, 14, "PM"],
  },
  {
    day: 25,
    sehri: [5, 2, "AM"],
    fajar: [5, 12, "AM"],
    sunrise: [6, 24, "AM"],
    dhuhur: [12, 15, "PM"],
    asr: [4, 21, "PM"],
    magrib: [6, 3, "PM"],
    isha: [7, 17, "PM"],
  },
  {
    day: 26,
    sehri: [5, 2, "AM"],
    fajar: [5, 12, "AM"],
    sunrise: [6, 24, "AM"],
    dhuhur: [12, 15, "PM"],
    asr: [4, 21, "PM"],
    magrib: [6, 3, "PM"],
    isha: [7, 17, "PM"],
  },
  {
    day: 27,
    sehri: [5, 2, "AM"],
    fajar: [5, 12, "AM"],
    sunrise: [6, 24, "AM"],
    dhuhur: [12, 15, "PM"],
    asr: [4, 21, "PM"],
    magrib: [6, 3, "PM"],
    isha: [7, 17, "PM"],
  },
  {
    day: 28,
    sehri: [5, 2, "AM"],
    fajar: [5, 12, "AM"],
    sunrise: [6, 24, "AM"],
    dhuhur: [12, 15, "PM"],
    asr: [4, 21, "PM"],
    magrib: [6, 3, "PM"],
    isha: [7, 17, "PM"],
  },
];
