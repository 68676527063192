export const october = [
  {
    day: 0,
    sehri: [0, 0, "AM"],
    fajar: [0, 0, "AM"],
    sunrise: [0, 0, "AM"],
    dhuhur: [0, 0, "PM"],
    asr: [0, 0, "PM"],
    magrib: [0, 0, "PM"],
    isha: [0, 0, "PM"],
  },
  {
    day: 1,
    sehri: [4, 29, "AM"],
    fajar: [4, 39, "AM"],
    sunrise: [5, 49, "AM"],
    dhuhur: [11, 51, "AM"],
    asr: [4, 6, "PM"],
    magrib: [5, 49, "PM"],
    isha: [7, 2, "PM"],
  },
  {
    day: 2,
    sehri: [4, 29, "AM"],
    fajar: [4, 39, "AM"],
    sunrise: [5, 49, "AM"],
    dhuhur: [11, 51, "AM"],
    asr: [4, 6, "PM"],
    magrib: [5, 49, "PM"],
    isha: [7, 2, "PM"],
  },
  {
    day: 3,
    sehri: [4, 29, "AM"],
    fajar: [4, 39, "AM"],
    sunrise: [5, 49, "AM"],
    dhuhur: [11, 51, "AM"],
    asr: [4, 6, "PM"],
    magrib: [5, 49, "PM"],
    isha: [7, 2, "PM"],
  },
  {
    day: 4,
    sehri: [4, 29, "AM"],
    fajar: [4, 39, "AM"],
    sunrise: [5, 49, "AM"],
    dhuhur: [11, 51, "AM"],
    asr: [4, 6, "PM"],
    magrib: [5, 49, "PM"],
    isha: [7, 2, "PM"],
  },
  {
    day: 5,
    sehri: [4, 30, "AM"],
    fajar: [4, 40, "AM"],
    sunrise: [5, 51, "AM"],
    dhuhur: [11, 50, "AM"],
    asr: [4, 3, "PM"],
    magrib: [5, 45, "PM"],
    isha: [6, 58, "PM"],
  },
  {
    day: 6,
    sehri: [4, 30, "AM"],
    fajar: [4, 40, "AM"],
    sunrise: [5, 51, "AM"],
    dhuhur: [11, 50, "AM"],
    asr: [4, 3, "PM"],
    magrib: [5, 45, "PM"],
    isha: [6, 58, "PM"],
  },
  {
    day: 7,
    sehri: [4, 30, "AM"],
    fajar: [4, 40, "AM"],
    sunrise: [5, 51, "AM"],
    dhuhur: [11, 50, "AM"],
    asr: [4, 3, "PM"],
    magrib: [5, 45, "PM"],
    isha: [6, 58, "PM"],
  },
  {
    day: 8,
    sehri: [4, 30, "AM"],
    fajar: [4, 40, "AM"],
    sunrise: [5, 51, "AM"],
    dhuhur: [11, 50, "AM"],
    asr: [4, 3, "PM"],
    magrib: [5, 45, "PM"],
    isha: [6, 58, "PM"],
  },
  {
    day: 9,
    sehri: [4, 30, "AM"],
    fajar: [4, 40, "AM"],
    sunrise: [5, 51, "AM"],
    dhuhur: [11, 50, "AM"],
    asr: [4, 3, "PM"],
    magrib: [5, 45, "PM"],
    isha: [6, 58, "PM"],
  },
  {
    day: 10,
    sehri: [4, 32, "AM"],
    fajar: [4, 42, "AM"],
    sunrise: [5, 53, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 59, "PM"],
    magrib: [5, 41, "PM"],
    isha: [6, 54, "PM"],
  },
  {
    day: 11,
    sehri: [4, 32, "AM"],
    fajar: [4, 42, "AM"],
    sunrise: [5, 53, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 59, "PM"],
    magrib: [5, 41, "PM"],
    isha: [6, 54, "PM"],
  },
  {
    day: 12,
    sehri: [4, 32, "AM"],
    fajar: [4, 42, "AM"],
    sunrise: [5, 53, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 59, "PM"],
    magrib: [5, 41, "PM"],
    isha: [6, 54, "PM"],
  },
  {
    day: 13,
    sehri: [4, 32, "AM"],
    fajar: [4, 42, "AM"],
    sunrise: [5, 53, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 59, "PM"],
    magrib: [5, 41, "PM"],
    isha: [6, 54, "PM"],
  },
  {
    day: 14,
    sehri: [4, 32, "AM"],
    fajar: [4, 42, "AM"],
    sunrise: [5, 53, "AM"],
    dhuhur: [11, 49, "AM"],
    asr: [3, 59, "PM"],
    magrib: [5, 41, "PM"],
    isha: [6, 54, "PM"],
  },
  {
    day: 15,
    sehri: [4, 34, "AM"],
    fajar: [4, 44, "AM"],
    sunrise: [5, 56, "AM"],
    dhuhur: [11, 48, "AM"],
    asr: [3, 55, "PM"],
    magrib: [5, 36, "PM"],
    isha: [6, 50, "PM"],
  },
  {
    day: 16,
    sehri: [4, 34, "AM"],
    fajar: [4, 44, "AM"],
    sunrise: [5, 56, "AM"],
    dhuhur: [11, 48, "AM"],
    asr: [3, 55, "PM"],
    magrib: [5, 36, "PM"],
    isha: [6, 50, "PM"],
  },
  {
    day: 17,
    sehri: [4, 34, "AM"],
    fajar: [4, 44, "AM"],
    sunrise: [5, 56, "AM"],
    dhuhur: [11, 48, "AM"],
    asr: [3, 55, "PM"],
    magrib: [5, 36, "PM"],
    isha: [6, 50, "PM"],
  },
  {
    day: 18,
    sehri: [4, 34, "AM"],
    fajar: [4, 44, "AM"],
    sunrise: [5, 56, "AM"],
    dhuhur: [11, 48, "AM"],
    asr: [3, 55, "PM"],
    magrib: [5, 36, "PM"],
    isha: [6, 50, "PM"],
  },
  {
    day: 19,
    sehri: [4, 34, "AM"],
    fajar: [4, 44, "AM"],
    sunrise: [5, 56, "AM"],
    dhuhur: [11, 48, "AM"],
    asr: [3, 55, "PM"],
    magrib: [5, 36, "PM"],
    isha: [6, 50, "PM"],
  },
  {
    day: 20,
    sehri: [4, 36, "AM"],
    fajar: [4, 46, "AM"],
    sunrise: [5, 58, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 51, "PM"],
    magrib: [5, 32, "PM"],
    isha: [6, 46, "PM"],
  },
  {
    day: 21,
    sehri: [4, 36, "AM"],
    fajar: [4, 46, "AM"],
    sunrise: [5, 58, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 51, "PM"],
    magrib: [5, 32, "PM"],
    isha: [6, 46, "PM"],
  },
  {
    day: 22,
    sehri: [4, 36, "AM"],
    fajar: [4, 46, "AM"],
    sunrise: [5, 58, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 51, "PM"],
    magrib: [5, 32, "PM"],
    isha: [6, 46, "PM"],
  },
  {
    day: 23,
    sehri: [4, 36, "AM"],
    fajar: [4, 46, "AM"],
    sunrise: [5, 58, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 51, "PM"],
    magrib: [5, 32, "PM"],
    isha: [6, 46, "PM"],
  },
  {
    day: 24,
    sehri: [4, 36, "AM"],
    fajar: [4, 46, "AM"],
    sunrise: [5, 58, "AM"],
    dhuhur: [11, 47, "AM"],
    asr: [3, 51, "PM"],
    magrib: [5, 32, "PM"],
    isha: [6, 46, "PM"],
  },
  {
    day: 25,
    sehri: [4, 38, "AM"],
    fajar: [4, 48, "AM"],
    sunrise: [6, 0, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 48, "PM"],
    magrib: [5, 28, "PM"],
    isha: [6, 42, "PM"],
  },
  {
    day: 26,
    sehri: [4, 38, "AM"],
    fajar: [4, 48, "AM"],
    sunrise: [6, 0, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 48, "PM"],
    magrib: [5, 28, "PM"],
    isha: [6, 42, "PM"],
  },
  {
    day: 27,
    sehri: [4, 38, "AM"],
    fajar: [4, 48, "AM"],
    sunrise: [6, 0, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 48, "PM"],
    magrib: [5, 28, "PM"],
    isha: [6, 42, "PM"],
  },
  {
    day: 28,
    sehri: [4, 38, "AM"],
    fajar: [4, 48, "AM"],
    sunrise: [6, 0, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 48, "PM"],
    magrib: [5, 28, "PM"],
    isha: [6, 42, "PM"],
  },
  {
    day: 29,
    sehri: [4, 38, "AM"],
    fajar: [4, 48, "AM"],
    sunrise: [6, 0, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 48, "PM"],
    magrib: [5, 28, "PM"],
    isha: [6, 42, "PM"],
  },
  {
    day: 30,
    sehri: [4, 38, "AM"],
    fajar: [4, 48, "AM"],
    sunrise: [6, 0, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 48, "PM"],
    magrib: [5, 28, "PM"],
    isha: [6, 42, "PM"],
  },
  {
    day: 31,
    sehri: [4, 38, "AM"],
    fajar: [4, 48, "AM"],
    sunrise: [6, 0, "AM"],
    dhuhur: [11, 46, "AM"],
    asr: [3, 48, "PM"],
    magrib: [5, 28, "PM"],
    isha: [6, 42, "PM"],
  },
];
