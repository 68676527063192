export const june = [
  {
    day: 0,
    sehri: [0, 0, "AM"],
    fajar: [0, 0, "AM"],
    sunrise: [0, 0, "AM"],
    dhuhur: [0, 0, "PM"],
    asr: [0, 0, "PM"],
    magrib: [0, 0, "PM"],
    isha: [0, 0, "PM"],
  },
  {
    day: 1,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 35, "PM"],
    magrib: [6, 46, "PM"],
    isha: [8, 11, "PM"],
  },
  {
    day: 2,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 35, "PM"],
    magrib: [6, 46, "PM"],
    isha: [8, 11, "PM"],
  },
  {
    day: 3,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 35, "PM"],
    magrib: [6, 46, "PM"],
    isha: [8, 11, "PM"],
  },
  {
    day: 4,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 35, "PM"],
    magrib: [6, 46, "PM"],
    isha: [8, 11, "PM"],
  },
  {
    day: 5,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 36, "PM"],
    magrib: [6, 47, "PM"],
    isha: [8, 12, "PM"],
  },
  {
    day: 6,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 36, "PM"],
    magrib: [6, 47, "PM"],
    isha: [8, 12, "PM"],
  },
  {
    day: 7,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 36, "PM"],
    magrib: [6, 47, "PM"],
    isha: [8, 12, "PM"],
  },
  {
    day: 8,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 36, "PM"],
    magrib: [6, 47, "PM"],
    isha: [8, 12, "PM"],
  },
  {
    day: 9,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 36, "PM"],
    magrib: [6, 47, "PM"],
    isha: [8, 12, "PM"],
  },
  {
    day: 10,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 1, "PM"],
    asr: [4, 37, "PM"],
    magrib: [6, 49, "PM"],
    isha: [8, 15, "PM"],
  },
  {
    day: 11,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 1, "PM"],
    asr: [4, 37, "PM"],
    magrib: [6, 49, "PM"],
    isha: [8, 15, "PM"],
  },
  {
    day: 12,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 1, "PM"],
    asr: [4, 37, "PM"],
    magrib: [6, 49, "PM"],
    isha: [8, 15, "PM"],
  },
  {
    day: 13,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 1, "PM"],
    asr: [4, 37, "PM"],
    magrib: [6, 49, "PM"],
    isha: [8, 15, "PM"],
  },
  {
    day: 14,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 1, "PM"],
    asr: [4, 37, "PM"],
    magrib: [6, 49, "PM"],
    isha: [8, 15, "PM"],
  },
  {
    day: 15,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 38, "PM"],
    magrib: [6, 51, "PM"],
    isha: [8, 17, "PM"],
  },
  {
    day: 16,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 38, "PM"],
    magrib: [6, 51, "PM"],
    isha: [8, 17, "PM"],
  },
  {
    day: 17,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 38, "PM"],
    magrib: [6, 51, "PM"],
    isha: [8, 17, "PM"],
  },
  {
    day: 18,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 38, "PM"],
    magrib: [6, 51, "PM"],
    isha: [8, 17, "PM"],
  },
  {
    day: 19,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 10, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 38, "PM"],
    magrib: [6, 51, "PM"],
    isha: [8, 17, "PM"],
  },
  {
    day: 20,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 11, "AM"],
    dhuhur: [12, 3, "PM"],
    asr: [4, 40, "PM"],
    magrib: [6, 52, "PM"],
    isha: [8, 18, "PM"],
  },
  {
    day: 21,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 11, "AM"],
    dhuhur: [12, 3, "PM"],
    asr: [4, 40, "PM"],
    magrib: [6, 52, "PM"],
    isha: [8, 18, "PM"],
  },
  {
    day: 22,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 11, "AM"],
    dhuhur: [12, 3, "PM"],
    asr: [4, 40, "PM"],
    magrib: [6, 52, "PM"],
    isha: [8, 18, "PM"],
  },
  {
    day: 23,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 11, "AM"],
    dhuhur: [12, 3, "PM"],
    asr: [4, 40, "PM"],
    magrib: [6, 52, "PM"],
    isha: [8, 18, "PM"],
  },
  {
    day: 24,
    sehri: [3, 36, "AM"],
    fajar: [3, 46, "AM"],
    sunrise: [5, 11, "AM"],
    dhuhur: [12, 3, "PM"],
    asr: [4, 40, "PM"],
    magrib: [6, 52, "PM"],
    isha: [8, 18, "PM"],
  },
  {
    day: 25,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 26,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 27,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 28,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 29,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 20, "PM"],
  },
  {
    day: 30,
    sehri: [3, 37, "AM"],
    fajar: [3, 47, "AM"],
    sunrise: [5, 12, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 53, "PM"],
    isha: [8, 20, "PM"],
  },
];
