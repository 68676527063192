export const august = [
  {
    day: 0,
    sehri: [0, 0, "AM"],
    fajar: [0, 0, "AM"],
    sunrise: [0, 0, "AM"],
    dhuhur: [0, 0, "PM"],
    asr: [0, 0, "PM"],
    magrib: [0, 0, "PM"],
    isha: [0, 0, "PM"],
  },
  {
    day: 1,
    sehri: [3, 58, "AM"],
    fajar: [4, 8, "AM"],
    sunrise: [5, 27, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 45, "PM"],
    isha: [8, 5, "PM"],
  },
  {
    day: 2,
    sehri: [3, 58, "AM"],
    fajar: [4, 8, "AM"],
    sunrise: [5, 27, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 45, "PM"],
    isha: [8, 5, "PM"],
  },
  {
    day: 3,
    sehri: [3, 58, "AM"],
    fajar: [4, 8, "AM"],
    sunrise: [5, 27, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 45, "PM"],
    isha: [8, 5, "PM"],
  },
  {
    day: 4,
    sehri: [3, 58, "AM"],
    fajar: [4, 8, "AM"],
    sunrise: [5, 27, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 42, "PM"],
    magrib: [6, 45, "PM"],
    isha: [8, 5, "PM"],
  },
  {
    day: 5,
    sehri: [4, 1, "AM"],
    fajar: [4, 11, "AM"],
    sunrise: [5, 29, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 2, "PM"],
  },
  {
    day: 6,
    sehri: [4, 1, "AM"],
    fajar: [4, 11, "AM"],
    sunrise: [5, 29, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 2, "PM"],
  },
  {
    day: 7,
    sehri: [4, 1, "AM"],
    fajar: [4, 11, "AM"],
    sunrise: [5, 29, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 2, "PM"],
  },
  {
    day: 8,
    sehri: [4, 1, "AM"],
    fajar: [4, 11, "AM"],
    sunrise: [5, 29, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 2, "PM"],
  },
  {
    day: 9,
    sehri: [4, 1, "AM"],
    fajar: [4, 11, "AM"],
    sunrise: [5, 29, "AM"],
    dhuhur: [12, 8, "PM"],
    asr: [4, 41, "PM"],
    magrib: [6, 42, "PM"],
    isha: [8, 2, "PM"],
  },
  {
    day: 10,
    sehri: [4, 4, "AM"],
    fajar: [4, 14, "AM"],
    sunrise: [5, 31, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 40, "PM"],
    magrib: [6, 39, "PM"],
    isha: [7, 58, "PM"],
  },
  {
    day: 11,
    sehri: [4, 4, "AM"],
    fajar: [4, 14, "AM"],
    sunrise: [5, 31, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 40, "PM"],
    magrib: [6, 39, "PM"],
    isha: [7, 58, "PM"],
  },
  {
    day: 12,
    sehri: [4, 4, "AM"],
    fajar: [4, 14, "AM"],
    sunrise: [5, 31, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 40, "PM"],
    magrib: [6, 39, "PM"],
    isha: [7, 58, "PM"],
  },
  {
    day: 13,
    sehri: [4, 4, "AM"],
    fajar: [4, 14, "AM"],
    sunrise: [5, 31, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 40, "PM"],
    magrib: [6, 39, "PM"],
    isha: [7, 58, "PM"],
  },
  {
    day: 14,
    sehri: [4, 4, "AM"],
    fajar: [4, 14, "AM"],
    sunrise: [5, 31, "AM"],
    dhuhur: [12, 7, "PM"],
    asr: [4, 40, "PM"],
    magrib: [6, 39, "PM"],
    isha: [7, 58, "PM"],
  },
  {
    day: 15,
    sehri: [4, 7, "AM"],
    fajar: [4, 17, "AM"],
    sunrise: [5, 33, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 38, "PM"],
    magrib: [6, 35, "PM"],
    isha: [7, 53, "PM"],
  },
  {
    day: 16,
    sehri: [4, 7, "AM"],
    fajar: [4, 17, "AM"],
    sunrise: [5, 33, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 38, "PM"],
    magrib: [6, 35, "PM"],
    isha: [7, 53, "PM"],
  },
  {
    day: 17,
    sehri: [4, 7, "AM"],
    fajar: [4, 17, "AM"],
    sunrise: [5, 33, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 38, "PM"],
    magrib: [6, 35, "PM"],
    isha: [7, 53, "PM"],
  },
  {
    day: 18,
    sehri: [4, 7, "AM"],
    fajar: [4, 17, "AM"],
    sunrise: [5, 33, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 38, "PM"],
    magrib: [6, 35, "PM"],
    isha: [7, 53, "PM"],
  },
  {
    day: 19,
    sehri: [4, 7, "AM"],
    fajar: [4, 17, "AM"],
    sunrise: [5, 33, "AM"],
    dhuhur: [12, 6, "PM"],
    asr: [4, 38, "PM"],
    magrib: [6, 35, "PM"],
    isha: [7, 53, "PM"],
  },
  {
    day: 20,
    sehri: [4, 10, "AM"],
    fajar: [4, 20, "AM"],
    sunrise: [5, 35, "AM"],
    dhuhur: [12, 5, "PM"],
    asr: [4, 36, "PM"],
    magrib: [6, 31, "PM"],
    isha: [7, 48, "PM"],
  },
  {
    day: 21,
    sehri: [4, 10, "AM"],
    fajar: [4, 20, "AM"],
    sunrise: [5, 35, "AM"],
    dhuhur: [12, 5, "PM"],
    asr: [4, 36, "PM"],
    magrib: [6, 31, "PM"],
    isha: [7, 48, "PM"],
  },
  {
    day: 22,
    sehri: [4, 10, "AM"],
    fajar: [4, 20, "AM"],
    sunrise: [5, 35, "AM"],
    dhuhur: [12, 5, "PM"],
    asr: [4, 36, "PM"],
    magrib: [6, 31, "PM"],
    isha: [7, 48, "PM"],
  },
  {
    day: 23,
    sehri: [4, 10, "AM"],
    fajar: [4, 20, "AM"],
    sunrise: [5, 35, "AM"],
    dhuhur: [12, 5, "PM"],
    asr: [4, 36, "PM"],
    magrib: [6, 31, "PM"],
    isha: [7, 48, "PM"],
  },
  {
    day: 24,
    sehri: [4, 10, "AM"],
    fajar: [4, 20, "AM"],
    sunrise: [5, 35, "AM"],
    dhuhur: [12, 5, "PM"],
    asr: [4, 36, "PM"],
    magrib: [6, 31, "PM"],
    isha: [7, 48, "PM"],
  },
  {
    day: 25,
    sehri: [4, 13, "AM"],
    fajar: [4, 23, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 33, "PM"],
    magrib: [6, 27, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    day: 26,
    sehri: [4, 13, "AM"],
    fajar: [4, 23, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 33, "PM"],
    magrib: [6, 27, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    day: 27,
    sehri: [4, 13, "AM"],
    fajar: [4, 23, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 33, "PM"],
    magrib: [6, 27, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    day: 28,
    sehri: [4, 13, "AM"],
    fajar: [4, 23, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 33, "PM"],
    magrib: [6, 27, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    day: 29,
    sehri: [4, 13, "AM"],
    fajar: [4, 23, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 33, "PM"],
    magrib: [6, 27, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    day: 30,
    sehri: [4, 13, "AM"],
    fajar: [4, 23, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 33, "PM"],
    magrib: [6, 27, "PM"],
    isha: [7, 43, "PM"],
  },
  {
    day: 31,
    sehri: [4, 13, "AM"],
    fajar: [4, 23, "AM"],
    sunrise: [5, 37, "AM"],
    dhuhur: [12, 4, "PM"],
    asr: [4, 33, "PM"],
    magrib: [6, 27, "PM"],
    isha: [7, 43, "PM"],
  },
];
