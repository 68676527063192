export const september = [
  {
    day: 0,
    sehri: [0, 0, "AM"],
    fajar: [0, 0, "AM"],
    sunrise: [0, 0, "AM"],
    dhuhur: [0, 0, "PM"],
    asr: [0, 0, "PM"],
    magrib: [0, 0, "PM"],
    isha: [0, 0, "PM"],
  },
  {
    day: 1,
    sehri: [4, 16, "AM"],
    fajar: [4, 26, "AM"],
    sunrise: [5, 39, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 20, "PM"],
    isha: [7, 35, "PM"],
  },
  {
    day: 2,
    sehri: [4, 16, "AM"],
    fajar: [4, 26, "AM"],
    sunrise: [5, 39, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 20, "PM"],
    isha: [7, 35, "PM"],
  },
  {
    day: 3,
    sehri: [4, 16, "AM"],
    fajar: [4, 26, "AM"],
    sunrise: [5, 39, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 20, "PM"],
    isha: [7, 35, "PM"],
  },
  {
    day: 4,
    sehri: [4, 16, "AM"],
    fajar: [4, 26, "AM"],
    sunrise: [5, 39, "AM"],
    dhuhur: [12, 2, "PM"],
    asr: [4, 29, "PM"],
    magrib: [6, 20, "PM"],
    isha: [7, 35, "PM"],
  },
  {
    day: 5,
    sehri: [4, 18, "AM"],
    fajar: [4, 28, "AM"],
    sunrise: [5, 41, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 26, "PM"],
    magrib: [6, 16, "PM"],
    isha: [7, 31, "PM"],
  },
  {
    day: 6,
    sehri: [4, 18, "AM"],
    fajar: [4, 28, "AM"],
    sunrise: [5, 41, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 26, "PM"],
    magrib: [6, 16, "PM"],
    isha: [7, 31, "PM"],
  },
  {
    day: 7,
    sehri: [4, 18, "AM"],
    fajar: [4, 28, "AM"],
    sunrise: [5, 41, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 26, "PM"],
    magrib: [6, 16, "PM"],
    isha: [7, 31, "PM"],
  },
  {
    day: 8,
    sehri: [4, 18, "AM"],
    fajar: [4, 28, "AM"],
    sunrise: [5, 41, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 26, "PM"],
    magrib: [6, 16, "PM"],
    isha: [7, 31, "PM"],
  },
  {
    day: 9,
    sehri: [4, 18, "AM"],
    fajar: [4, 28, "AM"],
    sunrise: [5, 41, "AM"],
    dhuhur: [12, 0, "PM"],
    asr: [4, 26, "PM"],
    magrib: [6, 16, "PM"],
    isha: [7, 31, "PM"],
  },
  {
    day: 10,
    sehri: [4, 20, "AM"],
    fajar: [4, 30, "AM"],
    sunrise: [5, 42, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 23, "PM"],
    magrib: [6, 11, "PM"],
    isha: [7, 25, "PM"],
  },
  {
    day: 11,
    sehri: [4, 20, "AM"],
    fajar: [4, 30, "AM"],
    sunrise: [5, 42, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 23, "PM"],
    magrib: [6, 11, "PM"],
    isha: [7, 25, "PM"],
  },
  {
    day: 12,
    sehri: [4, 20, "AM"],
    fajar: [4, 30, "AM"],
    sunrise: [5, 42, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 23, "PM"],
    magrib: [6, 11, "PM"],
    isha: [7, 25, "PM"],
  },
  {
    day: 13,
    sehri: [4, 20, "AM"],
    fajar: [4, 30, "AM"],
    sunrise: [5, 42, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 23, "PM"],
    magrib: [6, 11, "PM"],
    isha: [7, 25, "PM"],
  },
  {
    day: 14,
    sehri: [4, 20, "AM"],
    fajar: [4, 30, "AM"],
    sunrise: [5, 42, "AM"],
    dhuhur: [11, 59, "AM"],
    asr: [4, 23, "PM"],
    magrib: [6, 11, "PM"],
    isha: [7, 25, "PM"],
  },
  {
    day: 15,
    sehri: [4, 22, "AM"],
    fajar: [4, 32, "AM"],
    sunrise: [5, 44, "AM"],
    dhuhur: [11, 57, "AM"],
    asr: [4, 19, "PM"],
    magrib: [6, 6, "PM"],
    isha: [7, 20, "PM"],
  },
  {
    day: 16,
    sehri: [4, 22, "AM"],
    fajar: [4, 32, "AM"],
    sunrise: [5, 44, "AM"],
    dhuhur: [11, 57, "AM"],
    asr: [4, 19, "PM"],
    magrib: [6, 6, "PM"],
    isha: [7, 20, "PM"],
  },
  {
    day: 17,
    sehri: [4, 22, "AM"],
    fajar: [4, 32, "AM"],
    sunrise: [5, 44, "AM"],
    dhuhur: [11, 57, "AM"],
    asr: [4, 19, "PM"],
    magrib: [6, 6, "PM"],
    isha: [7, 20, "PM"],
  },
  {
    day: 18,
    sehri: [4, 22, "AM"],
    fajar: [4, 32, "AM"],
    sunrise: [5, 44, "AM"],
    dhuhur: [11, 57, "AM"],
    asr: [4, 19, "PM"],
    magrib: [6, 6, "PM"],
    isha: [7, 20, "PM"],
  },
  {
    day: 19,
    sehri: [4, 22, "AM"],
    fajar: [4, 32, "AM"],
    sunrise: [5, 44, "AM"],
    dhuhur: [11, 57, "AM"],
    asr: [4, 19, "PM"],
    magrib: [6, 6, "PM"],
    isha: [7, 20, "PM"],
  },
  {
    day: 20,
    sehri: [4, 25, "AM"],
    fajar: [4, 35, "AM"],
    sunrise: [5, 46, "AM"],
    dhuhur: [11, 55, "AM"],
    asr: [4, 15, "PM"],
    magrib: [6, 0, "PM"],
    isha: [7, 14, "PM"],
  },
  {
    day: 21,
    sehri: [4, 25, "AM"],
    fajar: [4, 35, "AM"],
    sunrise: [5, 46, "AM"],
    dhuhur: [11, 55, "AM"],
    asr: [4, 15, "PM"],
    magrib: [6, 0, "PM"],
    isha: [7, 14, "PM"],
  },
  {
    day: 22,
    sehri: [4, 25, "AM"],
    fajar: [4, 35, "AM"],
    sunrise: [5, 46, "AM"],
    dhuhur: [11, 55, "AM"],
    asr: [4, 15, "PM"],
    magrib: [6, 0, "PM"],
    isha: [7, 14, "PM"],
  },
  {
    day: 23,
    sehri: [4, 25, "AM"],
    fajar: [4, 35, "AM"],
    sunrise: [5, 46, "AM"],
    dhuhur: [11, 55, "AM"],
    asr: [4, 15, "PM"],
    magrib: [6, 0, "PM"],
    isha: [7, 14, "PM"],
  },
  {
    day: 24,
    sehri: [4, 25, "AM"],
    fajar: [4, 35, "AM"],
    sunrise: [5, 46, "AM"],
    dhuhur: [11, 55, "AM"],
    asr: [4, 15, "PM"],
    magrib: [6, 0, "PM"],
    isha: [7, 14, "PM"],
  },
  {
    day: 25,
    sehri: [4, 26, "AM"],
    fajar: [4, 36, "AM"],
    sunrise: [5, 47, "AM"],
    dhuhur: [11, 53, "AM"],
    asr: [4, 11, "PM"],
    magrib: [5, 56, "PM"],
    isha: [7, 9, "PM"],
  },
  {
    day: 26,
    sehri: [4, 26, "AM"],
    fajar: [4, 36, "AM"],
    sunrise: [5, 47, "AM"],
    dhuhur: [11, 53, "AM"],
    asr: [4, 11, "PM"],
    magrib: [5, 56, "PM"],
    isha: [7, 9, "PM"],
  },
  {
    day: 27,
    sehri: [4, 26, "AM"],
    fajar: [4, 36, "AM"],
    sunrise: [5, 47, "AM"],
    dhuhur: [11, 53, "AM"],
    asr: [4, 11, "PM"],
    magrib: [5, 56, "PM"],
    isha: [7, 9, "PM"],
  },
  {
    day: 28,
    sehri: [4, 26, "AM"],
    fajar: [4, 36, "AM"],
    sunrise: [5, 47, "AM"],
    dhuhur: [11, 53, "AM"],
    asr: [4, 11, "PM"],
    magrib: [5, 56, "PM"],
    isha: [7, 9, "PM"],
  },
  {
    day: 29,
    sehri: [4, 26, "AM"],
    fajar: [4, 36, "AM"],
    sunrise: [5, 47, "AM"],
    dhuhur: [11, 53, "AM"],
    asr: [4, 11, "PM"],
    magrib: [5, 56, "PM"],
    isha: [7, 9, "PM"],
  },
  {
    day: 30,
    sehri: [4, 26, "AM"],
    fajar: [4, 36, "AM"],
    sunrise: [5, 47, "AM"],
    dhuhur: [11, 53, "AM"],
    asr: [4, 11, "PM"],
    magrib: [5, 56, "PM"],
    isha: [7, 9, "PM"],
  },
];
